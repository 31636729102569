import { types, flow, isAlive } from 'mobx-state-tree';
import map from 'lodash/map';
import { isAfter } from 'date-fns';

import { sortBy, groupBy } from 'lodash';

import api from 'services/API';
import { stringUtilities } from 'utils';
import { getRootStore } from 'models/root';
import { Line } from 'models/types';
import pressures from 'config/pressures';
import { normalize } from 'utils/diacriticNormalizer';

export const linesInitialState = {
  isLoaded: false,
  all: [],
  expired: [],
  state: 'done',
  updated: null,
  currentLine: null,
  lastPourId: null,
  filters: {
    sortBy: 'lineIdentifier',
    filterBy: 'showAll',
    orderBy: 'asc',
    cooler: 'all',
  },
  searchString: '',
  isTableView: false,
};

const LineWithView = Line.views(self => ({
  get lineTap() {
    const root = getRootStore();
    return root.lineTapsStore.lineTaps.find(el => el.line_id === self.id);
  },

  get itemLine() {
    if (!isAlive(self)) {
      return void 0;
    }
    const root = getRootStore();
    return root.itemLinesStore.itemLines.find(el => el.line_id === self.id && el.queue_index === 0);
  },

  get item() {
    const root = getRootStore();
    return self.itemLine && root.itemsStore.items.find(el => el.id === self.itemLine.item_id);
  },

  get beverage() {
    const root = getRootStore();
    return self.item && root.beveragesStore.beverages.find(el => el.id === self.item.beverage_id);
  },

  get queue() {
    const { itemLinesStore } = getRootStore();
    if (
      itemLinesStore.itemLines &&
      Array.isArray(itemLinesStore.itemLines) &&
      itemLinesStore.itemLines.length > 0
    ) {
      return itemLinesStore.itemLines
        .filter(el => el.line_id === self.id)
        .filter(el => el.queue_index && el.queue_index !== 0)
        .sort((a, b) => a.queue_index - b.queue_index);
    } else {
      return [];
    }
  },

  get queuedItemsIDs() {
    return map(self.queue, 'item_id') || [];
  },

  get latestConditions() {
    return self?.latestPour?.conditions;
  },

  get pressureCondition() {
    if (self.latestConditions) {
      const foundCondition = [4, 5, 7, 8, 9].filter(num => self.latestConditions.includes(num));
      if (foundCondition) {
        return foundCondition[0];
      } else {
        return false;
      }
    }
    return false;
  },

  get temperatureCondition() {
    if (self.latestConditions) {
      const foundCondition = [3, 6].filter(num => self.latestConditions.includes(num));
      if (foundCondition) {
        return foundCondition[0];
      } else {
        return false;
      }
    }
    return false;
  },

  get linePressureString() {
    if (!self.itemLine) {
      return 'N/A';
    }

    if (self.pressureCondition) {
      return pressures[self.pressureCondition];
    } else {
      return 'Normal';
    }
  },

  get linePressureColor() {
    if (self.pressureCondition) {
      return 'red';
    }

    return 'white';
  },

  get lineTempColor() {
    if (self.item && self.temperatureCondition) {
      if (self.temperatureCondition === 6) {
        return 'blue';
      } else {
        return 'red';
      }
    }
    return 'white';
  },

  get latestPour() {
    return self._pours_latest_pour;
  },

  get latestHeartbeat() {
    return self._heartbeats_latest_heartbeat;
  },

  get lastPourAt() {
    return self.latestPour?.poured_at;
  },

  get latestTemperature() {
    let temperature;
    if (!self.latestPour?.poured_at && !self.latestHeartbeat?.received_at) return 'N/A';

    if (!self?.latestPour?.sensor_temp_c_stop || !self?.latestHeartbeat?.sensor_temp_c) {
      temperature = self?.latestPour?.sensor_temp_c_stop || self?.latestHeartbeat?.sensor_temp_c;
    } else {
      temperature = isAfter(
        new Date(self.latestHeartbeat.received_at),
        new Date(self.latestPour.poured_at),
      )
        ? self.latestHeartbeat.sensor_temp_c
        : self.latestPour.sensor_temp_c_stop;
    }

    return temperature;
  },

  get displayedTemperature() {
    if (self.latestTemperature === 'N/A' || !self?.latestTemperature) return 'N/A';
    return stringUtilities.makeTemperature(self.latestTemperature);
  },

  get isOverPoured() {
    if (self.item) {
      if (self.item.id && self.item._volume_remaining_ml && self.item._volume_total_ml) {
        if (self.item._volume_remaining_ml < -self.item._volume_total_ml * 0.03) {
          return true;
        }
      }
    }
    return false;
  },

  get nextQueuedItem() {
    const root = getRootStore();
    const nextItemLine = self.queue?.[0];

    if (nextItemLine) {
      return root.itemsStore.getItemById(nextItemLine.item_id);
    } else {
      return false;
    }
  },

  get cooler() {
    const root = getRootStore();
    return root.coolersStore.list.find(cooler => cooler.id === self.cooler_id);
  },

  get sensor() {
    const root = getRootStore();
    const lineSensor = root.topologyManagementStore.lineSensors.find(ls => ls.line_id === self.id);
    return root.topologyManagementStore.sensors.find(sensor => sensor.id === lineSensor?.sensor_id);
  },

  get bars() {
    const root = getRootStore();
    if (self?._bar_ids) {
      const bars = self._bar_ids.reduce((acc, barId) => {
        acc = {
          ...acc,
          [barId]: root.barsStore.getBarById(barId),
        };

        return acc;
      }, {});
      return bars;
    }

    return [];
  },

  get identifier() {
    return self?.identifiers?.line.prefix === 'Line'
      ? `#${self.identifiers.line?.numerical}`
      : self?.line_identifier
      ? `#${self.line_identifier}`
      : '';
  },

  get segments() {
    return self.geometries?.segments || [];
  },

  get tapIdentifiers() {
    return self?.identifiers.taps.length
      ? self?.identifiers.taps.map(tap => tap.numerical || tap.numericals).join(', ')
      : 'N/A';
  },
}));

export const linesModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(LineWithView),
    expired: types.array(LineWithView),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    currentLine: types.maybeNull(types.reference(LineWithView)),
    lastPourId: types.maybeNull(types.number),
    searchString: types.string,
    filters: types.maybeNull(
      types.model({
        sortBy: types.enumeration('sortBy', [
          'lineIdentifier',
          'beverageName',
          'producerName',
          'kegLevel',
          'dateTapped',
          'queuedKegs',
          'identifier',
          'lastCleaning',
        ]),
        filterBy: types.enumeration('filterBy', [
          'showAll',
          'emptyLines',
          'emptyQueues',
          'emptyKegs',
        ]),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
        cooler: types.maybeNull(types.union(types.number, types.string)),
      }),
    ),
    isTableView: types.boolean,
  })
  .views(self => ({
    get lines() {
      return sortBy(self.all, 'sort_value');
    },

    get activeLines() {
      return self.all
        .filter(line => line.status_code !== 2 && line.itemLine)
        .sort((a, b) => a.sort_value - b.sort_value);
    },

    get queuedLines() {
      return self.all
        .filter(line => line.status_code !== 2 && line.itemLine && line.itemLine.queue_index === 1)
        .sort((a, b) => a.sort_value - b.sort_value);
    },

    get linesIDs() {
      return Array.isArray(self.lines) && self.lines.length > 0
        ? self.lines.map(line => line.id)
        : [];
    },

    get bmCorrectionLines() {
      return self.lines.filter(line => line.item && line.beverage);
    },

    getLine(id) {
      return [...self.all, ...self.expired].find(el => el.id === id);
    },

    getTapIdentifierByLineID(id) {
      return (
        self.all
          .slice()
          .find(el => el.id === Number(id))
          ?.identifiers.taps.map(tap => tap.numerical || tap.numericals)
          .join(', ') || 'N/A'
      );
    },

    getTapIdentifierByItemID(id) {
      return (
        self.all
          .slice()
          .find(el => el?.item?.id === Number(id))
          ?.identifiers.taps.map(tap => tap.numerical || tap.numericals)
          .join(', ') || 'N/A'
      );
    },

    get overPouredIds() {
      return self.all.filter(line => line.isOverPoured).map(el => el.identifier);
    },

    get hasOverPoured() {
      return self.overPouredIds.length > 0;
    },

    get getUpdated() {
      return self.updated;
    },

    get sortedLines() {
      let result = self.all || [];

      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');
        result = result.filter(item => {
          if (!item.beverage) return false;

          return searchArray.every(
            element =>
              normalize(item.beverage._name).toLowerCase().includes(element) ||
              normalize(item.beverage._producers_name).toLowerCase().includes(element),
          );
        });
      }

      switch (self.filters.sortBy) {
        case 'showAll':
          result = result.sort((a, b) => a.sort_value - b.sort_value);
          break;
        case 'beverageName':
          result = sortBy(result, 'item.beverage._name');
          break;
        case 'producerName':
          result = sortBy(result, 'item.beverage._producers_name');
          break;
        case 'kegLevel':
          result = result
            .slice()
            .sort((a, b) => Math.max(0, a.item?.percentage) - Math.max(0, b.item?.percentage));
          break;
        case 'dateTapped':
          result = result
            .slice()
            .sort(
              (a, b) =>
                Date.parse(a.itemLine?.connected_from) - Date.parse(b.itemLine?.connected_from),
            );
          break;
        case 'queuedKegs':
          result = result.slice().sort((a, b) => a.queue.length - b.queue.length);
          break;

        case 'lineIdentifier':
          result = result.slice().sort((a, b) => a.sort_value - b.sort_value);
          break;

        case 'lastCleaning':
          result = result.slice().sort((a, b) => a.last_cleaning_at - b.last_cleaning_at);
          break;

        default:
          result = result.slice().sort((a, b) => a.queue.length - b.queue.length);
          break;
      }

      switch (self.filters.filterBy) {
        case 'emptyLines':
          result = result.filter(e => !e.itemLine);
          break;
        case 'emptyQueues':
          result = result.filter(e => e.queue.length === 0);
          break;
        case 'emptyKegs':
          result = result.filter(e => e.item?.percentage <= 10);
          break;
        default:
          break;
      }

      if (self.filters.orderBy === 'desc') {
        result.reverse();
      }

      return result;
    },

    get groupedLines() {
      let filteredByCoolerId = self.sortedLines;
      if (self.filters.cooler !== 'all') {
        filteredByCoolerId = self.sortedLines.filter(
          item => item.cooler_id === self.filters.cooler,
        );
      }

      return groupBy(filteredByCoolerId, 'cooler.name');
    },

    get isDefaultFilters() {
      return (
        self.filters.sortBy === linesInitialState.filters.sortBy &&
        self.filters.orderBy === linesInitialState.filters.orderBy &&
        self.filters.filterBy === linesInitialState.filters.filterBy &&
        self.filters.cooler === 'all'
      );
    },
  }))
  .actions(self => {
    return {
      handleCleanStart({ lines } = []) {
        lines &&
          Array.isArray(lines) &&
          lines.forEach(line => {
            self.updateLine({
              ...line,
              _cleanings_cleaned_from_current: new Date().toISOString(),
            });
          });
      },

      handleCleanStop({ lines } = []) {
        lines && Array.isArray(lines) && lines.forEach(self.updateLine);
      },

      handleItemDisconnection({ _lines = [] } = {}) {
        _lines.forEach(self.updateLine);
      },

      getItem: flow(function* (itemId) {
        const root = getRootStore();
        const { data } = yield api.getItem(itemId);

        if (
          self.currentLine &&
          self.currentLine.item._volume_remaining_ml !== data.result._volume_remaining_ml
        ) {
          root.itemsStore.updateItem({
            ...self.currentLine.item,
            _volume_remaining_ml: data.result._volume_remaining_ml,
          });
        }
      }),

      fetch: flow(function* () {
        try {
          self.state = 'pending';
          const response = yield api.getLines();
          if (response?.data?.result) {
            self.all.replace(response.data.result);
            self.updated = new Date();
            self.state = 'done';
            self.isLoaded = true;
          } else {
            self.state = 'done';
            self.isLoaded = true;
          }
        } catch (err) {
          self.isLoaded = true;
          self.state = 'error';
          console.error(err);
          return Promise.reject(err);
        }
      }),

      fetchLine: flow(function* (line_id) {
        try {
          const response = yield api.getLine(line_id);
          if (response?.data?.result) {
            return self.updateLine(response.data.result);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),
      patchLine: flow(function* (id, body) {
        try {
          const response = yield api.patchLine(body, id);

          if (response?.data?.row) {
            return self.updateLine(response.data.row);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateLine(_line) {
        const index = self.all.findIndex(line => line && line.id === _line.id);
        const { line, taps } = _line.identifiers || self.all[index]?.identifiers || {};

        if (index >= 0) {
          Object.assign(self.all[index], {
            ..._line,
            identifiers: { line: { ...line }, taps: Array.isArray(taps) ? [...taps] : [] },
          });
        }
      },

      getLineById(id) {
        return self.all.find(line => line.id === id);
      },

      setCurrentLine(id) {
        self.currentLine = id;
      },

      handleNextItem({ disconnected = {}, connected = {} } = {}) {
        const { _lines: disconnectedLines } = disconnected;
        const { _lines: connectedLines } = connected;

        disconnectedLines &&
          Array.isArray(disconnectedLines) &&
          disconnectedLines.forEach(self.updateLine);
        connectedLines && Array.isArray(connectedLines) && connectedLines.forEach(self.updateLine);
      },

      handleHeartbeat(result) {
        result &&
          Array.isArray(result) &&
          result.forEach(heartbeat => {
            const line = self.getLineById(heartbeat.line_id);
            if (line) {
              self.updateLine({
                ...line,
                _heartbeats_latest_heartbeat: heartbeat,
              });
            }
          });
      },

      setLines(lines) {
        const root = getRootStore();

        const liveLineIds = root.lineTapsStore.lineTaps.map(e => e.line_id);
        const alive = lines.filter(({ id }) => liveLineIds.includes(id));
        const expired = lines.filter(({ id }) => !liveLineIds.includes(id));

        self.all.replace(alive);
        self.expired.replace(expired);
        self.updated = new Date();
        self.state = 'done';
        self.isLoaded = true;
      },

      setTableView(isTable) {
        self.isTableView = isTable;
      },

      setLastPourId(id) {
        self.lastPourId = id;
      },

      setFilters(filters) {
        if (filters === null) {
          self.filters = {
            sortBy: linesInitialState.filters.sortBy,
            filterBy: linesInitialState.filters.filterBy,
            orderBy: linesInitialState.filters.orderBy,
            cooler: 'all',
          };
        } else {
          self.filters = {
            sortBy: filters.sortBy || self.filters.sortBy,
            filterBy: filters.filterBy || self.filters.filterBy,
            orderBy: filters.orderBy || self.filters.orderBy,
            cooler: filters.cooler || self.filters.cooler,
          };
        }
      },

      resetFilters() {
        self.filters = {
          sortBy: linesInitialState.filters.sortBy,
          orderBy: linesInitialState.filters.orderBy,
          filterBy: linesInitialState.filters.filterBy,
          cooler: 'all',
        };
      },

      setSearch(value) {
        self.searchString = value || '';
      },

      handleProcessPour({ pour }) {
        if (!pour) return;
        const line = self.all.find(line => line.id === pour._line_id);
        if (line) {
          const { poured_at, received_at } = pour;
          line._pours_latest_pour.poured_at = poured_at;
          line._pours_latest_pour.received_at = received_at;
          self.updateLine(line.id, line);
          self.updated = new Date();
        }
      },

      handlePourRule(result) {
        const { pour_id, conditions, line_id } = result;
        const line = self.getLine(line_id);

        // Exit early if no line or no latest pour is found
        if (!line?.latestPour) {
          return;
        }

        const currentConditions = line?.latestConditions || [];
        const receivedConditions = conditions.toString();
        let newConditions;

        if (!self.lastPourId) {
          self.setLastPourId(pour_id);
        }

        if (pour_id === self.lastPourId) {
          newConditions = currentConditions.concat(Number(receivedConditions));
        } else {
          self.setLastPourId(pour_id);
          newConditions = conditions;
        }

        self.updateLine({
          ...line,
          _pours_latest_pour: {
            ...line.latestPour,
            conditions: newConditions,
          },
        });
      },

      patch: flow(function* (IDs, payload) {
        try {
          const response = yield api.patchLines(payload, { id: IDs, rows: IDs.length });
          if (response?.data?.result) {
            response.data.result.forEach(row => self.updateLine(row));
            return response.data.result;
          }
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        }
      }),
    };
  });
