// Only for SSO/login right now
export const USER_AUTHENTICATE = '/auth/local';
export const USER_LOGOUT = '/auth/logout';

// Authentication
// Query string define is it log in or registration ?mode=<login/signup>
export const USER_GRANT = '/user/grant';
export const USER_ACCEPT_GRANT = '/user/accept';
export const USER_RESET_PASSWORD = '/user/reset';
export const USER_FORGOT_PASSWORD = '/user/forgot';
export const USER_RESEND_INVITATION = '/user/resend';
export const USER_ROLE_SWITCH = '/user/switch';
export const USER_CANCEL_INVITATION = '/user/cancel_invite';

// Generic
// List of get requests with exceptions, see comments
export const ROLES = '/generic/roles/';
export const ROLE = '/generic/roles/:id'; // origin from postman GET
export const USERS = '/generic/users/';
export const USER = '/generic/users/:id'; // origin from postman GET
export const ROLE_USER = '/generic/role_users/:id'; // origin from postman GET
export const ROLE_USERS = '/generic/role_users'; // origin from postman GET
export const BEVERAGES = '/generic/beverages/'; // origin from postman GET, also for POST
export const BEVERAGE = '/generic/beverages/:id'; // origin from postman GET
export const LINE_STATISTICS = '/generic/lines_statistics/:id'; // origin from postman GET
export const LINES_STATISTICS = '/generic/lines_statistics/';
export const BRUS = '/generic/brus/';
export const BRU = '/generic/brus/:id'; // origin from postman GET
export const CARRIERS = '/generic/carriers/';
export const CONTAINERS = '/generic/containers/';
export const CONTAINER = '/generic/containers/:id'; // origin from postman GET
export const ESTABLISHMENTS = '/generic/establishments/';
export const ESTABLISHMENT = '/generic/establishments/:id'; // origin from postman GET, also for PATCH
export const ITEM_LINES = '/generic/item_lines/';
export const ITEM_LINE = '/generic/item_lines/:id'; // origin from postman GET
export const TAP_EVENTS = '/generic/tap_events/';
export const TAP_EVENT = '/generic/tap_events/:id'; // origin from postman GET
export const TAPS = '/generic/taps/';
export const TAP = '/generic/taps/:id'; // origin from postman GET
export const LINE_TAPS = '/generic/line_taps/';
export const LINE_TAP = '/generic/line_taps/:id'; // origin from postman GET
export const SKUS = '/generic/skus/'; // origin from postman GET
export const SKUSES = '/generic/skus/:id';
export const PRODUCERS = '/generic/producers/';
export const PRODUCER = '/generic/producers/:id'; // origin from postman GET
export const GATEWAYS = '/generic/gateways/';
export const NOTIFICATION = '/generic/notifications/:id';
export const GATEWAY = '/generic/gateways/:id'; // origin from postman GET
export const ITEM_EVENT = '/generic/item_events/:id'; // origin from postman GET
export const ITEM_STATISTICS = '/generic/items_statistics/:id';
export const ITEMS_STATISTICS = '/generic/items_statistics/';
export const ITEM = '/generic/items/:id';
export const ITEMS = '/generic/items/';
export const USER_BAR_ROLES = '/generic/user_bar_roles/';
export const USER_BAR_ROLE = '/generic/user_bar_roles/:id'; // origin from postman GET
export const SENSORS = '/generic/sensors/';
export const SENSOR = '/generic/sensors/:id'; // origin from postman GET
export const LINE_SENSORS = '/generic/line_sensors/';
export const LINE_SENSOR = '/generic/line_sensors/:id'; // origin from postman GET
export const UNITS = '/generic/units';
export const SCHEDULES = '/generic/schedules';
export const SCHEDULE = '/generic/schedules/:id';
export const SCHEDULE_TYPES = '/generic/type_lists';
export const BUSINESS_HOURS = '/generic/bus_hours';
export const HAPPY_HOURS = '/generic/happy_hours';
export const EVENTS = '/generic/events';
export const INTEGRATIONS = '/generic/integrations';
export const POS_SKUS = '/generic/pos_skus';
export const POS_SKU = '/generic/pos_skus/:id';
export const CLEANINGS = '/generic/cleanings';
export const MENUS = '/generic/menus';
export const MENU = '/generic/menus/:id';
export const MENU_DESIGNS = '/generic/menu_designs';
export const MENU_DESIGN = '/generic/menu_designs/:id';
export const MENU_LISTS = '/generic/menu_lists';
export const MENU_LIST = '/generic/menu_lists/:id';
export const LINES = '/generic/lines';
export const LINE = '/generic/lines/:id';
export const COOLERS = '/generic/coolers';
export const COOLER = '/generic/coolers/:id';
export const CLEANINGS_STATISTICS = '/generic/cleanings_statistics';
export const STATISTIC_TEST = 'generic/statistic_tests/:id';
// export const ITEM_AUDIT_EVENTS = '/generic/audits_items';
export const ITEM_AUDIT_EVENTS = '/generic/audits';

export const GENERATE_MENU_QR_CODE = '/menu/:uuid/qr';

export const REPORT = '/generic/report_configurations';
export const ALERTS = '/generic/micromatic.alarms';

export const ROLE_USERS_FLAG = '/user/roleuser_flags '; // origin from postman GET
export const ACTIVATE_MENU = '/user/activate_menu_screen';

// Specific
// List of POST requests from postman
export const COPY_CONTAINER = '/specific/copy_container'; // query -> ?establishment_id=1&prototype_container_id=13
export const COPY_BEVERAGE = '/specific/copy_beverages'; // query -> ?establishment_id=1&prototype_beverage_id=52
export const BEVERAGES_CREATE = '/specific/create_beverages/'; // origin from postman  POST
export const LINE_NEXT_ITEM = '/specific/line_next_item';
export const ITEM_LINE_REORDER = '/specific/itemline_reorder';
export const CLEAN_START = '/specific/clean_start';
export const CLEAN_STOP = '/specific/clean_stop';
export const CLEAN_SCHEDULE = '/specific/clean_schedule';
export const CLEAN_MODIFY = '/specific/clean_modify';
export const CLEAN_ADD = '/specific/clean_add';
export const CLEAN_REMOVE = '/specific/clean_remove';
export const ADD_INVENTORY = '/specific/add_items/'; // example of query ?copies=1 (max 15)
export const REORDER_CONTAINERS = '/specific/skus_reorder';
export const REPORT_INVENTORY_ANALYTICS = '/specific/get_inventory_statistics';
export const GET_INVENTORY_KEG_RATE = '/specific/get_inventory_keg_rate';
export const ADJUST_KEG_LEVEL = '/specific/adjust_keg_level';
export const CLEANING_RESET_STATISTICS = '/specific/clean_reset_statistics';

// List of GET requests from postman
export const SEARCH_BEVERAGES = '/specific/search_beverages/'; // query ?tsquery=ipa:*%26new:*%26bel:*&establishment_id=1&mode=copies
// mode=prototypes (Excluding Copied)
// export const CHANGE_USER_ROLE = '/specific/change_user_role'; // deprecated

export const ADD_ITEM_TO_BACK = '/specific/item_to_line_back/'; // example of query ?line_id=21&item_id=1887
export const DISCONNECT_CURRENT_ITEM = '/specific/line_disconnect_current_item/'; // query ?line_id=21&item_status_code=2
export const REMOVE_ITEM_LINE = '/specific/itemline_remove/'; // example of query ?item_line_id=615
export const ADD_ITEM_TO_FRONT = '/specific/item_to_line_front/'; // example of query ?line_id=21&item_id=1489
export const CONNECT_NEW_ITEM = '/specific/line_connect_new_item/'; // example of query ?line_id=21&item_id=2105
export const REMOVE_INVENTORY = '/specific/remove_items/'; // example of query ?item_id=231&item_event_type=15'
export const SEARCH_PRODUCERS = '/specific/search_producers/'; // query -> ?tsquery=brewery:*
export const CLEANING_STATISTICS = '/specific/get_cleaning_statistics';
export const CHANGE_CONTAINER_UNITS = '/specific/change_container_units';
export const FIX_ITEM_ITEM_LINES = '/specific/get_fixitem_item_lines';
export const APPLY_ITEM_LINE_FIXES = '/specific/apply_item_line_fixes';
export const ITEM_LINES_CORRECT = '/sfpecific/itemlines_correct';
export const GENERATE_REPORT = '/specific/generate_report';
export const SWAP = '/specific/swap_items';
export const SEND_REPORT = '/specific/contact_us';
export const BUNDLE = '/specific/get_bundle';
export const DISABLE_ALARMS = 'specific/alarms_opt_out';

export const PUBLIC_ROUTES = [USER_AUTHENTICATE, USER_FORGOT_PASSWORD, USER_LOGOUT];

//teporary stub
export const BARS = '/generic/bars/';
export const BAR = '/generic/bars/:id';

export const BAR_ROLE_USERS = '/generic/bars_role_users/:id';
export const BARS_ROLE_USERS = '/generic/bars_role_users/';

export const DEVICE_HISTORY = '/setup/device_history';
export const COOLER_BRU_SUMMARY = '/specific/cooler_bru_summary';
export const LINE_DISCONNECT_SENSOR = '/specific/line_disconnect_current_sensor';
export const LINE_CONNECT_NEW_TAP = '/specific/line_connect_new_tap';
export const LINE_CONNECT_NEW_SENSOR = '/specific/line_connect_new_sensor';
export const LINE_DISCONNECT_TAP = '/specific/line_disconnect_current_tap';
export const DEVICE_CONNECTIVITY_HISTORY = '/setup/device_connectivity_history';
export const SENSOR_DIAGNOSTIC_INFO = '/specific/sensor_diagnostics';
export const REPLACE_BRU = '/specific/replace_bru';
