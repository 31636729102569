import { types, flow, detach } from 'mobx-state-tree';
import { differenceInDays } from 'date-fns';

import api from 'services/API';
import { dateUtilities, commonUtilities } from 'utils';
import { eventsIcons } from 'config/statuses';
import statuses from 'config/statuses';
import { getRootStore } from 'models/root';
import { Item } from 'models/types';

const { full, saved, kicked, sold, expired, empty, mistake, infected } = statuses;

export const itemsInitialState = {
  isLoaded: false,
  isHistoryLoaded: false,
  all: [],
  state: 'done',
  updated: null,
  reservedItems: [],
};

const ItemWithViews = Item.views(self => ({
  get isQueued() {
    return !!self._queued_count;
  },

  get lineQueuedOn() {
    if (!self.isQueued) return false;
    const root = getRootStore();
    if (self.itemLine) {
      const line = root.linesStore.getLineById(self.itemLine.line_id);
      return line.identifier;
    } else {
      return false;
    }
  },

  get isAvailable() {
    return self._queued_count === 0 && self.status_code !== 5;
  },

  get isAvailableForSameKeg() {
    return self.status_code === 2;
  },

  get beverage() {
    const root = getRootStore();
    return root.beveragesStore.beverages.find(el => el.id === self.beverage_id);
  },

  get container() {
    const root = getRootStore();
    return root.containersStore.containers.find(el => el.id === self.container_id);
  },

  get containerName() {
    const root = getRootStore();
    const container = root.containersStore.containers.find(el => el.id === self.container_id);
    if (!container) return '';

    const {
      nickname,
      volume_total,
      volume_unit: { abbreviation },
    } = self.container;

    return `${nickname} (${volume_total.toFixed(2)}${abbreviation})`;
  },

  get age() {
    let addedDate = new Date(self?.addedEvent?.earliest_at).getTime();
    let currentDate = new Date().getTime();
    return Math.ceil(Math.abs(currentDate - addedDate));
  },

  get dynamicAddedFromDate() {
    const date = dateUtilities.secondsToDuration(
      dateUtilities.getTimestampToNowInSeconds(self.addedEvent?.earliest_at),
    );
    return date;
  },

  get isExpired() {
    let useByDate = new Date(self.use_by).getTime();
    let currentDate = new Date().getTime();
    return useByDate < currentDate;
  },

  get expiredDays() {
    return differenceInDays(Date.now(), Date.parse(self.use_by));
  },

  get percentage() {
    return self._volume_remaining_ml === null && self.status_code === 0
      ? 100
      : Math.round((self._volume_remaining_ml / self._containers_volume_total_ml) * 100);
  },

  get latestEvent() {
    const dates = {
      Added: new Date(self.addedEvent?.earliest_at).getTime(),
      ...(self.savedEvent && { Saved: new Date(self.savedEvent?.earliest_at).getTime() }),
    };
    const latestDate = Math.max(...Object.keys(dates).map(date => dates[date]));
    let latestEvent;
    Object.keys(dates).forEach(eventName => {
      if (dates[eventName] === latestDate) {
        latestEvent = eventName;
      }
    });
    return {
      name: latestEvent,
      date: dateUtilities.makeDateFormatMDYY(latestDate),
      icon: eventsIcons[latestEvent],
    };
  },

  //TODO check if getters itemLine/tap are used somewhere
  get itemLine() {
    const root = getRootStore();
    return root.itemLinesStore.itemLines.find(
      itemLine => itemLine.item_id === self.id && itemLine.queue_index !== null,
    );
  },

  get allItemLines() {
    const root = getRootStore();
    return root.itemLinesStore.itemLines.filter(itemLine => itemLine.item_id === self.id);
  },

  get tap() {
    if (!self.itemLine) {
      return null;
    }

    const root = getRootStore();
    const lineTap = root.lineTapsStore.lineTaps.find(
      lineTap => lineTap.id === self.itemLine.line_id,
    );
    return lineTap && root.tapsStore.taps.find(tap => tap.id === lineTap.tap_id);
  },

  get itemLines() {
    const root = getRootStore();
    if (
      root.itemLinesStore.itemLines &&
      Array.isArray(root.itemLinesStore.itemLines) &&
      root.itemLinesStore.itemLines.length > 0
    ) {
      return root.itemLinesStore.itemLines.filter(
        itemLine => itemLine.item_id === self.id && itemLine.queue_index !== null,
      );
    } else {
      return [];
    }
  },

  get taps() {
    const root = getRootStore();
    if (self.itemLines && Array.isArray(self.itemLines) && self.itemLines.length > 0) {
      const lineTaps = self.itemLines
        .map(itemLine => {
          return root.lineTapsStore.lineTaps.find(lineTap => lineTap.line_id === itemLine.line_id);
        })
        .filter(lineTap => Boolean(lineTap));
      if (lineTaps && Array.isArray(lineTaps) && lineTaps.length > 0) {
        return lineTaps.map(lineTap => root.tapsStore.taps.find(tap => tap.id === lineTap.tap_id));
      } else {
        return [];
      }
    } else {
      return [];
    }
  },

  get kegSKU() {
    const root = getRootStore();
    if (self.beverage && self.beverage.id && self.container && self.container.id) {
      return root.skuStore.getKegSKUsByBeverageAndContainer(self.beverage.id, self.container.id);
    }
    return null;
  },
  get tappedEvent() {
    return (
      self._item_events_summary &&
      self._item_events_summary.find(({ type_code }) => type_code === 5)
    );
  },
  get queuedEvent() {
    return (
      self._item_events_summary &&
      self._item_events_summary.find(({ type_code }) => type_code === 6)
    );
  },
  get addedEvent() {
    return self?._item_events_summary?.find(({ type_code }) => type_code === 0);
  },
  get savedEvent() {
    return (
      self._item_events_summary &&
      self._item_events_summary.find(({ type_code }) => type_code === 2)
    );
  },
  get lastInventoryEvent() {
    const added = self.addedEvent?.latest_at;
    const saved = self.savedEvent?.latest_at;
    if (!added) return self.savedEvent;
    if (!saved) return self.addedEvent;

    return new Date(added) < new Date(saved) ? self.savedEvent : self.addedEvent;
  },
  get lastEvent() {
    return (
      self._item_events_summary &&
      self._item_events_summary
        .slice()
        .sort((a, b) => new Date(b.latest_at).getTime() - new Date(a.latest_at).getTime())[0]
    );
  },

  get cooler() {
    const root = getRootStore();
    return root.coolersStore.list.find(cooler => cooler.id === self.cooler_id);
  },
})).actions(self => {
  const root = getRootStore();
  return {
    removeItemFromInventory: flow(function* (id, name) {
      try {
        root.itemsStore.setState('pending');

        const response = yield api.removeItemFromInventory({
          item_id: id,
          item_event_type: statuses[name],
        });
        if (commonUtilities.validateResponse(response)) {
          root.itemsStore.removeItem(response.data.result);
          if (
            response.data.result._item_lines_deleted &&
            response.data.result._item_lines_deleted.length
          ) {
            let updatedItemLines = root.itemLinesStore.itemLines;
            for (const deletedItemLine of response.data.result._item_lines_deleted) {
              updatedItemLines = updatedItemLines.filter(
                itemLine => itemLine.id !== deletedItemLine.id,
              );
            }
            root.itemLinesStore.updateItemLines(updatedItemLines);
          }
          if (response.data.result._item_event) {
            root.itemEventsStore.updateOrInsertItemEvent(response.data.result._item_event);
          }
        }
        root.itemsStore.setState('done');
        return response;
      } catch (err) {
        root.itemsStore.setState('error');
        return Promise.reject(err);
      }
    }),
    removeItemFromQueue: flow(function* () {
      const root = getRootStore();
      if (self.itemLines.length > 0) {
        try {
          root.itemsStore.setState('pending');
          //TODO for Dave: api.removeItemFromTheQueue should pass an array
          const updatedItemLines = yield Promise.all(
            self.itemLines.map(itemLine =>
              api.removeItemFromTheQueue({ item_line_id: itemLine.id }),
            ),
          );
          if (updatedItemLines && Array.isArray(updatedItemLines) && updatedItemLines.length > 0) {
            (async () => {
              try {
                updatedItemLines.forEach(itemLine => {
                  const removed = itemLine?.data?.result?.removed || [];
                  removed.forEach(removedItemLine => {
                    root.itemEventsStore.updateOrInsertItemEvent(removedItemLine?._item_event);
                    root.itemLinesStore.updateItemLine(removedItemLine._item_line);
                  });
                });
                const affectedItemId =
                  updatedItemLines[0].data.result?.removed?.[0]?._item_line?.item_id;
                const updatedItem = await api.getItem(affectedItemId);
                if (commonUtilities.validateResponse(updatedItem)) {
                  await root.itemsStore.updateItem(updatedItem.data.result);
                  root.itemsStore.setState('done');
                }
              } catch (err) {
                root.itemsStore.setState('error');
              }
            })();
            return updatedItemLines;
          }
        } catch (err) {
          root.itemsStore.setState('error');
          return Promise.reject(err);
        }
      }
    }),
    assignItemToQueue: flow(function* (isFront, tapID) {
      try {
        const root = getRootStore();
        const addItemToQueue = isFront ? api.addNewItemToTheFront : api.addNewItemToTheBack;
        root.itemsStore.setState('pending');
        const response = yield addItemToQueue({
          item_id: self.id,
          line_id: tapID,
        });
        if (commonUtilities.validateResponse(response)) {
          const [added] = response.data.result?.added || [];
          root.itemLinesStore.updateItemLine(added._item_line);
          if (added._item_event) {
            root.itemEventsStore.updateOrInsertItemEvent(added._item_event);
          }
          const res = yield api.getItem(added._item_line?.item_id);
          (async () => {
            try {
              root.itemsStore.updateItem(res.data.result);
              root.itemsStore.setState('done');
            } catch (err) {
              root.itemsStore.setState('error');
            }
          })();
        }
        return response;
      } catch (err) {
        root.itemsStore.setState('error');
        return Promise.reject(err);
      }
    }),
  };
});

export const itemsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(ItemWithViews),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    reservedItems: types.array(ItemWithViews),
  })
  .views(self => ({
    get items() {
      return self.all;
    },
    get getUpdated() {
      return self.updated;
    },

    get inventoryItems() {
      const root = getRootStore();
      const filteredItems = self.items.filter(
        item =>
          item.establishment_id === root.userStore.currentRole._establishment_id &&
          item._containers_type_code === 1 &&
          [full, saved].includes(item.status_code),
      );

      const filteredItemsWithAvailableContainers = filteredItems.filter(item =>
        root.containersStore.all.some(c => c.id === item.container_id),
      );
      return filteredItemsWithAvailableContainers;
    },

    get historyItems() {
      const root = getRootStore();
      const filteredItems = self.items.filter(
        item =>
          item.establishment_id === root.userStore.currentRole._establishment_id &&
          item._containers_type_code === 1 &&
          [kicked, sold, expired, empty, mistake, infected].includes(item.status_code),
      );
      const filteredItemsWithAvailableContainers = filteredItems.filter(item =>
        root.containersStore.all.some(c => c.id === item.container_id),
      );
      return filteredItemsWithAvailableContainers;
    },

    get itemsForCorrection() {
      return self.reservedItems;
    },

    get tappedItems() {
      return self.items.filter(({ status_code }) => status_code === 5);
    },

    tappedItemsByBeverageId(beverage_id) {
      return self.tappedItems.filter(item => item.beverage_id === beverage_id);
    },
  }))
  .actions(self => {
    return {
      handlePour({ pour } = {}) {
        const { _item_id, poured_at } = pour || {};
        const item = self.all.find(item => item.id === _item_id);
        if (item) item._pours_latest_pour_at = poured_at;
      },

      handleAddItem(items) {
        if (Array.isArray(items)) {
          self.all.push(...items);
        }
      },

      handleRemoveItem(itemToRemove) {
        const item = self.all.find(item => item.id === itemToRemove.id);
        if (item) {
          self.all.remove(item);
        }
      },

      handleNewItemConnection({ connected = [] } = {}) {
        connected.forEach(({ _item }) => {
          const itemExists = self.all.find(item => item.id === _item.id);

          if (itemExists) {
            self.updateItem(_item);
          } else {
            self.all.push(_item);
          }
        });
      },

      handleItemDisconnection({ disconnected = [] } = {}) {
        disconnected.forEach(({ _item }) => self.updateItem(_item));
      },

      handleNextItem({ disconnected = {}, connected = {} } = {}) {
        const { _items: disconnectedItems } = disconnected;
        const { _items: connectedItems } = connected;

        disconnectedItems &&
          Array.isArray(disconnectedItems) &&
          disconnectedItems.forEach(self.updateItem);
        connectedItems && Array.isArray(connectedItems) && connectedItems.forEach(self.updateItem);
      },

      handleSwapItems({ _items = [] }) {
        _items && Array.isArray(_items) && _items.forEach(self.updateOrInsertItem);
      },

      fetchItems: flow(function* () {
        const root = getRootStore();
        try {
          self.setState('pending');
          const response = yield api.getItems({
            status_code: [0, 2, 5],
          });
          if (response && response.data && response.data.result) {
            self.all.replace(response.data.result);
            self.updated = new Date();
            self.setState('done');
            self.isLoaded = true;
            root.inventory.setDefaultFilterBeerTypes();
          } else {
            self.setState('done');
            self.isLoaded = true;
          }
        } catch (err) {
          self.isLoaded = false;
          self.setState('error');
          console.error(err);
          return Promise.reject(err);
        }
      }),

      fetchAllItems: flow(function* () {
        const root = getRootStore();
        try {
          self.setState('pending');
          const response = yield api.getItems();
          if (response && response.data && response.data.result) {
            self.all.replace(response.data.result);
            self.updated = new Date();
            self.setState('done');
            self.isLoaded = true;
            self.isHistoryLoaded = true;
            root.inventory.setDefaultFilterBeerTypes();
          } else {
            self.setState('done');
            self.isLoaded = true;
          }
        } catch (err) {
          self.isLoaded = false;
          self.setState('error');
          return Promise.reject(err);
        }
      }),

      fetchItem: flow(function* (itemId) {
        const response = yield api.getItem(itemId);

        if (response?.data?.result) {
          return self.updateItem(response.data.result);
        }
      }),

      patchItem: flow(function* (id, body) {
        try {
          yield api.patchItem(id, body);

          const updatedItemResponse = yield api.getItem(id);

          if (updatedItemResponse?.data?.result) {
            self.updateOrInsertItem(updatedItemResponse.data.result);
          }

          return updatedItemResponse.data.result;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      patchItems: flow(function* (beverage_id, container_id, rows, body, statusCodes = [0, 2, 5]) {
        try {
          const response = yield api.updateItems(body, {
            beverage_id,
            container_id,
            status_code: statusCodes,
            rows,
          });
          if (response && response.data && response.data.result) {
            response.data.result.forEach(self.updateOrInsertItem);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateItem(_item) {
        if (_item) {
          self.all.replace(
            self.all.map(item => {
              if (item.id === _item.id) {
                return { ...item, ..._item };
              }
              return item;
            }),
          );
        }
      },

      updateOrInsertItem(_item) {
        if (_item && _item.id) {
          const itemIndex = self.all.findIndex(item => item && item.id === _item.id);
          const itemExist = itemIndex >= 0;
          if (itemExist) {
            self.all[itemIndex] = { ...self.all[itemIndex], ..._item };
          } else {
            const updatedItems = [...self.all, _item];
            self.all.replace(updatedItems);
          }
        }
      },

      removeItem(_item) {
        const index = self.all.findIndex(el => el.id === _item.id);
        if (index !== -1) {
          detach(self.all[index]);
        }
        self.reservedItems.replace(self.reservedItems.filter(item => item.id !== _item.id));
      },

      reserveItem(_item) {
        const index = self.all.findIndex(el => el.id === _item.id);
        if (index === -1) {
          return false;
        }
        const detached = detach(self.all[index]);
        self.reservedItems = [...self.reservedItems, detached];
        return true;
      },

      reorderReservedItems(items) {
        self.reservedItems.replace(items);
      },

      addItem(_item) {
        const index = self.reservedItems.findIndex(el => el.id === _item.id);
        if (index === -1) {
          return false;
        }
        const detached = detach(self.reservedItems[index]);
        self.all.replace([...self.all, detached]);
        return true;
      },

      addItems() {
        self.all.replace([...self.all, ...self.reservedItems.map(detach)]);
      },

      getItemById(id) {
        return self.all.find(item => item.id === parseInt(id));
      },

      updateItems(updatedItems) {
        self.all.replace(updatedItems);
      },

      queueInventoryReplacement: flow(function* (item_id) {
        try {
          const response = yield api.getItem(item_id);
          self.updateItem(response.data.result);
        } catch (err) {
          // called only in models actions. can't throw error
          console.error(err);
        }
      }),

      adjustKegLevel: flow(function* (body) {
        try {
          const response = yield api.adjustKegLevel(body);

          self.updateItem(response.data.result);
        } catch (err) {
          console.error(err);
          return Promise.reject(err);
        }
      }),

      setState(state) {
        self.state = state;
      },

      setItems(items) {
        const root = getRootStore();
        self.all.replace(items);
        self.updated = new Date();
        self.setState('done');
        self.isLoaded = true;
        root.inventory.setDefaultFilterBeerTypes();
      },

      removeItemsFromInventory: flow(function* (ids = [], name) {
        const root = getRootStore();
        try {
          root.itemsStore.setState('pending');
          const response = yield api.removeItemsFromInventory({
            item_ids: ids,
            item_event_type: statuses[name],
          });
          if (commonUtilities.validateResponse(response)) {
            const { removed } = response.data.result;

            if (removed && removed.length > 0) {
              removed.forEach(removedItem => {
                root.itemsStore.removeItem(removedItem._item);
                root.itemLinesStore.updateItemLines(
                  root.itemLinesStore.itemLines.filter(e => e.item_id !== removedItem._item.id),
                );
                root.itemEventsStore.updateOrInsertItemEvent(removedItem._item_event);
              });
            }
          }
          root.itemsStore.setState('done');
          return response;
        } catch (err) {
          root.itemsStore.setState('error');
          return Promise.reject(err);
        }
      }),

      handleProcessPour({ pour }) {
        if (!pour) return;
        const { linesStore } = getRootStore();
        const line = linesStore.all.find(line => line.id === pour?._line_id);
        if (line) {
          const { corrected_total_ml } = pour;
          self.updateItem({
            ...line.item,
            _volume_remaining_ml: line?.item?._volume_remaining_ml - corrected_total_ml,
          });
        }
      },
    };
  });
