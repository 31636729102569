import { types } from 'mobx-state-tree';
import { getRootEnv, getRootStore } from 'models/root';

import socketClient from 'services/socketClient';

const TOKEN = 'bar_track_pwa_token';
export const socketsInitialState = {};
export const socketsModel = types.model({}).actions(self => {
  // eslint-disable-next-line no-unused-vars
  let socket;
  return {
    onStorageChange(event) {
      const { userStore } = getRootStore();
      if (event instanceof StorageEvent) {
        if (event.key === TOKEN) {
          if (event.newValue) {
            window.location.reload();
          } else {
            userStore.logout();
          }
        }
      }
    },
    subscribe() {
      const {
        userStore,
        linesStore,
        beveragesStore,
        containersStore,
        establishmentStore,
        itemLinesStore,
        itemsStore,
        skuStore,
        itemEventsStore,
        cleaningsStore,
        topologyManagementStore,
        linesStatisticsStore,
      } = getRootStore();
      if (userStore.isAuthenticated) {
        socketClient.connect(userStore.auth_token);
      }
      window.addEventListener('storage', self.onStorageChange, false);
      const env = getRootEnv();
      if (env && env.socket && env.socket.connection) {
        env.socket.connection.removeAllListeners('notification');
        socket = env.socket.connection.on('notification', message => {
          const { operation, result, by, tableName } = message || {};
          if (userStore.profile && userStore.profile.id !== by) {
            if (
              result &&
              tableName === 'establishments' &&
              establishmentStore.establishment.id === result.id
            ) {
              return establishmentStore.setEstablishment(result);
            }
            switch (operation) {
              case 'copy_beverage': {
                return beveragesStore.handleBeverageCopy(result);
              }
              case 'create_beverages': {
                return beveragesStore.handleCreatedBeverages(result);
              }
              case 'updateGeneric': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuUpdate(result);
                }
                if (tableName === 'beverages') {
                  return beveragesStore.handleBeverageUpdate(result);
                }
                if (tableName === 'containers') {
                  return containersStore.updateContainerById(result);
                }
                break;
              }
              case 'deleteGenericCollection':
              case 'deleteGenericElement': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuDelete(result);
                }
                if (tableName === 'beverages') {
                  return beveragesStore.handleBeverageDelete(result);
                }
                if (tableName === 'containers') {
                  return containersStore.removeContainer(result);
                }
                break;
              }
              case 'createGeneric': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuCreate(result);
                }
                if (tableName === 'containers') {
                  return containersStore.addContainer(result);
                }
                break;
              }
              case 'line_connect_new_item': {
                itemsStore.handleNewItemConnection(result);
                itemLinesStore.handleNewItemConnection(result);
                break;
              }
              case 'line_disconnect_current_item': {
                linesStore.handleItemDisconnection(result);
                itemsStore.handleItemDisconnection(result);
                itemLinesStore.handleItemDisconnection(result);
                break;
              }
              case 'item_to_line_queueindex': {
                itemLinesStore.handleNewItemLine(result);
                itemEventsStore.handleNewItemLine(result);
                break;
              }
              case 'itemline_remove': {
                itemLinesStore.handleItemLineRemove(result);
                itemEventsStore.handleItemLineRemove(result);
                break;
              }
              case 'itemline_reorder': {
                return itemLinesStore.handleItemLineReorder(result);
              }
              case 'line_next_item': {
                itemsStore.handleNextItem(result);
                itemLinesStore.handleNextItem(result);
                itemEventsStore.handleNextItem(result);
                linesStore.handleNextItem(result);
                break;
              }
              case 'swap_items': {
                itemsStore.handleSwapItems(result);
                itemLinesStore.handleSwapItems(result);
                itemEventsStore.handleSwapItems(result);
                break;
              }
              case 'add_item': {
                itemsStore.handleAddItem(result);
                itemEventsStore.handleAddItem(result);
                break;
              }
              case 'add_items': {
                itemsStore.handleAddItem(result._items_added);
                itemEventsStore.handleAddItem(result._item_events);
                break;
              }
              case 'remove_item': {
                itemsStore.handleRemoveItem(result);
                itemEventsStore.handleRemoveItem(result);
                break;
              }
              case 'processPour': {
                linesStore.handleProcessPour(result);
                topologyManagementStore.handleProcessPour(result);
                linesStatisticsStore.handleProcessPour(result);
                itemsStore.handleProcessPour(result);
                break;
              }
              case 'clean_start': {
                linesStore.handleCleanStart(result);
                cleaningsStore.handleCleanStart(result);
                break;
              }
              case 'clean_stop': {
                linesStore.handleCleanStop(result);
                cleaningsStore.handleCleanStop(result);
                break;
              }
              case 'processSensorHeartbeat': {
                linesStore.handleHeartbeat(result);
                topologyManagementStore.handleHeartbeat(result);
                establishmentStore.handleHeartbeat(result);
                break;
              }
              default:
                return;
            }
          } else {
            // Fix for BP-829
            // We have to invoke this and for current app user
            // Manually removing item line duplicates from the front-end, as the server does not update the existing item line,
            // but instead creates a new record which gets populated in the app state and as a result we had multiple records.
            // This issue occurs if during the keg correction one of the queued items was used.
            if (operation === 'line_connect_new_item') {
              const { connected } = result || {};

              connected.forEach(connectedItem => {
                const { _item_line } = connectedItem || {};
                itemLinesStore.removeDuplicateItemLineRecord(_item_line);
              });
            }
          }
        });
        socket = env.socket.connection.on('alert', message => {
          const { operation, result } = message || {};
          switch (operation) {
            case 'pourRule': {
              linesStore.handlePourRule(result);
              linesStatisticsStore.handlePourRule(result);
              break;
            }
            default:
              break;
          }
        });
      }
    },

    unsubscribe() {
      window.removeEventListener('storage', self.onStorageChange);
    },
  };
});
