import { applySnapshot, getEnv, getRoot, getSnapshot, onAction, types } from 'mobx-state-tree';
import socketClient from 'services/socketClient';

import { uiModel, uiInitialState } from './ui';
import { userModel, userInitialState } from './userStore';
import { usersModel, usersInitialState } from './usersStore';
import { inventoryModel, inventoryInitialState } from './inventory';
import { containersModel, containersInitialState } from './containersStore';
import { establishmentModel, establishmentInitialState } from './establishmentStore';
import { unitModel, unitsInitialState } from './unitStore';
import { linesModel, linesInitialState } from './linesStore';
import { itemsModel, itemsInitialState } from './itemsStore';
import { itemLinesModel, itemLinesInitialState } from './itemLinesStore';
import { beveragesModel, beveragesInitialState } from './beveragesStore';
import { skuStore, skuInitialState } from 'models/skuStore';
import { cleaningStatisticsModel, cleaningStatisticsInitialState } from './cleaningStatisticsStore';
import {
  cleaningsStatisticsModel,
  cleaningsStatisticsInitialState,
} from './cleaningsStatisticsStore';
import { scheduleModel, scheduleModelInitialData } from 'models/scheduleStore';
import { kegCorrectionModel, kegCorrectionInitialState } from './kegCorrectionStore';
import { tapsInitialState, tapsModel } from './tapsStore';
import { lineTapsModel, lineTapsInitialState } from './lineTapsStore';
import { kegQueueModel, kegQueueInitialState } from './keqQueueController';
import { itemLineControllerModel, itemLineControllerInitialState } from './itemLineController';
import { skuMappingStore, skuMappingStoreInitialState } from 'models/skuMappingStore';
import { socketsModel, socketsInitialState } from './socketsController';
import { itemEventsModel, itemEventsInitialState } from './itemEventsStore';
import { cleaningsModel, cleaningsInitialState } from './cleaningsStore';
import { integrationsInitialState, integrationsStore } from 'models/integrationsStore';
import { roleUserStoreInitialState, roleUserStore } from 'models/roleUserStore';
import { reportModel, reportInitialState } from './reportStore';
import { menusInitialState, menusModel } from './menusStore';
import { menuListsInitialState, menuListsModel } from './menuListsStore';
import { menuDesignsInitialState, menuDesignsModel } from './menuDesignsStore';
import { inventoryKegRateInitialState, inventoryKegRateModel } from './inventoryKegRateStore';
import { linesStatisticsInitialState, linesStatisticsModel } from './linesStatisticsStore';
import { coolersInitialState, coolersModel } from './coolersStore';
import { barsModel, barsInitialState } from './barsStore';
import { barsRoleUserModel, barsRoleUserInitialState } from './barsRoleUserStore';
import { deviceHistoryModel, deviceHistoryInitialState } from './deviceHistoryStore';
import { topologyManagementModel, topologyManagementInitialState } from './topologyManagementStore';
import { notificationModel, notificationInitialState } from './notificationsStore';
import { statisticTestsInitialState, statisticTestsModel } from './statisticTestsStore';

const initialSnapshot = {
  ui: uiInitialState,
  userStore: userInitialState,
  usersStore: usersInitialState,
  inventory: inventoryInitialState,
  beveragesStore: beveragesInitialState,
  linesStore: linesInitialState,
  itemsStore: itemsInitialState,
  itemLinesStore: itemLinesInitialState,
  skuStore: skuInitialState,
  containersStore: containersInitialState,
  establishmentStore: establishmentInitialState,
  unitStore: unitsInitialState,
  cleaningStatisticsStore: cleaningStatisticsInitialState,
  cleaningsStatisticsStore: cleaningsStatisticsInitialState,
  scheduleStore: scheduleModelInitialData,
  kegCorrectionStore: kegCorrectionInitialState,
  tapsStore: tapsInitialState,
  lineTapsStore: lineTapsInitialState,
  keqQueueController: kegQueueInitialState,
  skuMappingStore: skuMappingStoreInitialState,
  itemLineController: itemLineControllerInitialState,
  socketsController: socketsInitialState,
  itemEventsStore: itemEventsInitialState,
  cleaningsStore: cleaningsInitialState,
  integrationsStore: integrationsInitialState,
  roleUserStore: roleUserStoreInitialState,
  reportStore: reportInitialState,
  menusStore: menusInitialState,
  menuDesignsStore: menuDesignsInitialState,
  menuListsStore: menuListsInitialState,
  inventoryKegRateStore: inventoryKegRateInitialState,
  linesStatisticsStore: linesStatisticsInitialState,
  coolersStore: coolersInitialState,
  barsStore: barsInitialState,
  barsRoleUserStore: barsRoleUserInitialState,
  deviceHistoryStore: deviceHistoryInitialState,
  topologyManagementStore: topologyManagementInitialState,
  notificationsStore: notificationInitialState,
  statisticTestsStore: statisticTestsInitialState,
};

const RootModel = types
  .model({
    ui: uiModel,
    userStore: userModel,
    usersStore: usersModel,
    inventory: inventoryModel,
    beveragesStore: beveragesModel,
    linesStore: linesModel,
    itemsStore: itemsModel,
    itemLinesStore: itemLinesModel,
    skuStore: skuStore,
    containersStore: containersModel,
    establishmentStore: establishmentModel,
    unitStore: unitModel,
    cleaningStatisticsStore: cleaningStatisticsModel,
    cleaningsStatisticsStore: cleaningsStatisticsModel,
    scheduleStore: scheduleModel,
    kegCorrectionStore: kegCorrectionModel,
    tapsStore: tapsModel,
    lineTapsStore: lineTapsModel,
    keqQueueController: kegQueueModel,
    skuMappingStore: skuMappingStore,
    itemLineController: itemLineControllerModel,
    socketsController: socketsModel,
    itemEventsStore: itemEventsModel,
    cleaningsStore: cleaningsModel,
    integrationsStore: integrationsStore,
    roleUserStore: roleUserStore,
    reportStore: reportModel,
    menusStore: menusModel,
    menuDesignsStore: menuDesignsModel,
    menuListsStore: menuListsModel,
    inventoryKegRateStore: inventoryKegRateModel,
    linesStatisticsStore: linesStatisticsModel,
    coolersStore: coolersModel,
    barsStore: barsModel,
    barsRoleUserStore: barsRoleUserModel,
    deviceHistoryStore: deviceHistoryModel,
    topologyManagementStore: topologyManagementModel,
    notificationsStore: notificationModel,
    statisticTestsStore: statisticTestsModel,
  })
  .actions(self => ({
    resetAllExcept(exceptions = []) {
      const snapshot = { ...initialSnapshot };
      setTimeout(() => {
        if (exceptions.length > 0) {
          exceptions.forEach(exception => {
            if (exception in snapshot) {
              snapshot[exception] = getSnapshot(self[exception]);
            }
          });
        }
        applySnapshot(self, snapshot);
      }, 100);
    },
  }));

export let rootStore = RootModel.create(initialSnapshot, {
  socket: socketClient,
});

export const getRootStore = () => getRoot(rootStore);
export const getRootEnv = () => getEnv(rootStore);

onAction(
  rootStore,
  call => {
    const { name } = call;
    switch (name) {
      case 'changeEstablishment':
        rootStore.resetAllExcept(['userStore']);
        break;
      case 'logout':
        rootStore.resetAllExcept([]);
        break;
      default:
    }
  },
  true,
);
